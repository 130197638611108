import {
  //   Contexts,
  //   ContextualPrefs,
  CurrentUserData,
  PermissionId,
} from "../schemas/core";

export const hasPermissions = (
  currentUserData: CurrentUserData,
  ...requiredPermissions: PermissionId[]
): boolean => {
  const currentPermissions = currentUserData.permissions.reduce(
    (result: Record<string, boolean>, p) => ({
      ...result,
      [p.id]: true,
    }),
    {},
  );
  return (
    !!currentPermissions &&
    requiredPermissions.every((pid) => currentPermissions[pid])
  );
};

// export const assembleContextualPrefs = (
//   prefs: RecordTypePrefs,
//   context: keyof PrefsByRecordType
// ) => {
//   return Object.entries(prefs).reduce((previous, [k, recordTypes]) => {
//     return { ...previous, [k]: recordTypes[context] };
//   }, {}) as ContextualPrefs;
// };
