export const PERMISSIONS = [
  "manage_tokens",
  "add_edit_remove_roles",
  "add_edit_users",
  "remove_users",
  "manage_email_automation",
  "add_search_field_boosts",
  "request_email_reports",
  "view_public_users",
  "update_user_profile",
  "manage_authorization",
  "perform_high_volume_queries",
  "view_fulfillment_config",
  "manage_fulfillment_config",
  "manage_fulfillments",
  "manage_shopify_webhooks",
  "update_shopify_order_data",
  "view_public_comments",
  "view_unpublished_comments",
  "add_public_comments",
  "add_mnhs_comments",
  "add_mdh_comments",
  "edit_comments",
  "delete_comments",
  "view_comment_emails",
  "certify_comments",
  "manage_comment_subscriptions",
  "view_cmt_subscriptions",
  "check_cmt_subscription",
  "add_remove_cmt_subscriptions",
  "view_cmt_blocked_emails",
  "add_cmt_blocked_emails",
  "view_cmt_sub_url_pats",
  "edit_cmt_sub_url_pats",
  "view_public_birth_records",
  "view_confidential_birth_records",
  "view_unpublished_birth_records",
  "add_edit_publish_remove_birth_records",
  "bulk_update_birth_records",
  "view_birth_record_journal",
  "edit_birth_record_search_field_boosts",
  "view_public_census_records",
  "add_edit_remove_census_records",
  "bulk_update_census_records",
  "view_census_record_journal",
  "edit_census_record_search_field_boosts",
  "view_public_collections_items",
  "view_public_collections_media",
  "view_unpublished_collections_media",
  "edit_remove_collections_items",
  "edit_collections_item_search_field_boosts",
  "view_public_death_records",
  "view_confidential_death_records",
  "view_unpublished_death_records",
  "add_edit_publish_remove_death_records",
  "bulk_update_death_records",
  "view_death_record_journal",
  "edit_death_record_search_field_boosts",
  "view_public_find_aids",
  "view_unpublished_find_aids",
  "add_edit_remove_find_aids",
  "view_gold_star_roll_records",
  "add_edit_remove_gold_star_roll_records",
  "bulk_update_gold_star_roll_records",
  "view_gold_star_roll_record_journal",
  "edit_gold_star_roll_search_field_boosts",
  "view_vets_grave_records",
  "add_edit_remove_vets_grave_records",
  "bulk_update_vets_grave_records",
  "view_vets_graves_record_journal",
  "edit_vets_graves_record_search_field_boosts",
  "view_public_wwi_service_records",
  "add_edit_remove_wwi_service_records",
  "bulk_update_wwi_service_records",
  "view_wwi_service_record_journal",
  "edit_wwi_service_record_search_field_boosts",
  "view_public_wwi_bonus_records",
  "add_edit_remove_wwi_bonus_records",
  "view_wwi_bonus_record_journal",
  "edit_wwi_bonus_record_search_field_boosts",
  "view_public_wwi_soldiers_photos_records",
  "edit_remove_wwi_soldiers_photos_records",
  "edit_wwi_soldiers_photos_record_search_field_boosts"
] as const;
