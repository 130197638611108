import {
  Button,
  Layout,
  Page,
  ResourceItem,
  ResourceList,
  Spinner,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { Heading, PText } from "../../shared/TextComponents";
import {
  useRemoveDeprecatedPermsFromRoles,
  useRoles,
} from "../hooks/roleHooks";
import { CurrentUserData, Role } from "../schemas/core";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";

interface RolesPageProps {
  currentUserData: CurrentUserData;
}

export default function RolesPage(props: RolesPageProps) {
  const navigate = useNavigate();

  const { data: roles } = useRoles(props.currentUserData.accessToken);
  const mutation = useRemoveDeprecatedPermsFromRoles();

  const genRoleItem = (r: Role) => {
    return (
      <ResourceItem
        id={r.id}
        accessibilityLabel={`Edit data for ${r.name}`}
        onClick={() => navigate(`./${r.id}`)}
      >
        <PText weight="bold">{r.name}</PText>
      </ResourceItem>
    );
  };

  const roleList = roles && (
    <ResourceList
      resourceName={{ singular: "role", plural: "roles" }}
      items={roles}
      renderItem={genRoleItem}
    />
  );

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            <Stack>
              <Heading>Role Management</Heading>
              {roleList}
              <Stack direction="row">
                <Button
                  variant="primary"
                  tone="success"
                  onClick={() => navigate("./add")}
                >
                  New Role
                </Button>
                <Button
                  disabled={mutation.isLoading}
                  onClick={async () =>
                    await mutation.mutateAsync({
                      accessToken: props.currentUserData.accessToken,
                    })
                  }
                >
                  Remove Deprecated Permissions
                </Button>
                {mutation.isLoading && <Spinner size="small" />}
              </Stack>
              {mutation.data && <PText>{mutation.data}</PText>}
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
