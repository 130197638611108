import { Button, Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
} from "../Detail";
import {
  useCensusEvents,
  useCensusImage,
  useCensusPage,
  useCensusPageImage,
  useCensusRecord,
} from "../hooks/censusHooks";
import EventHistory from "../journal/EventHistory";
import { censusLabels, CensusRecord } from "../schemas/census";
import Skeleton from "../Skeleton";
import { DefaultPageProps } from "../utils/shared";
import ZoomableImage from "../ZoomableImage";
import CommentSection from "./CommentSection";
import { getVitalRecordsDisplayName } from "./shared";
import {
  checkCommentCertPerms,
  DefaultVitalRecsCommenter,
} from "../schemas/comment";

export function CensusDetail(props: BaseDetailCompProps<CensusRecord>) {
  const navigate = useNavigate();
  const censusRecord = props.record;

  const { data: censusPage } = useCensusPage(
    props.currentUserData.accessToken,
    censusRecord?.pageId || undefined,
  );

  const { data: image, isLoading: imageLoading } = useCensusPageImage(
    props.currentUserData.accessToken,
    censusPage?.SHA256Hash,
  );

  const skeleton = !censusRecord && <Skeleton lines={20} />;

  const imageSpinner = imageLoading && !image && <Spinner size="large" />;

  const imageDisplay = !imageLoading && image && (
    <Stack>
      <Subheading>Census Page</Subheading>
      <ZoomableImage
        src={image.content}
        alt="Page from which this census record came."
        fileName={censusRecord?.certificateId}
      />
      <Stack direction="row">
        {censusPage ? (
          <Button onClick={() => navigate(`../page/${censusPage.id}`)}>
            Go to Page
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );

  const showEditBtn = hasPermissions(
    props.currentUserData,
    "add_edit_remove_census_records",
  );

  const heading = censusRecord && (
    <Stack spacing={1}>
      <Heading>{getVitalRecordsDisplayName(censusRecord)}</Heading>
      <Subheading>{censusRecord.id}</Subheading>
    </Stack>
  );

  const detail = censusRecord && (
    <Stack spacing={4}>
      {imageSpinner || imageDisplay}
      <Divider />
      <DetailFields
        record={censusRecord}
        labels={censusLabels}
        spec={{
          certificateId: null,
          prefix: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          gender: null,
          age: null,
          ethnicityOther: null,
          birthPlace: null,
          fatherBirthPlace: null,
          motherBirthPlace: null,
          censusDateStart: { dateType: "date" },
          censusDateEnd: { dateType: "date" },
          county: null,
          ward: null,
          locality: null,
          ycord: null,
          lineInImage: null,
          legacyId: null,
        }}
      >
        <ListFieldStack
          label={censusLabels["alternateNames"]}
          elements={censusRecord.alternateNames || []}
        />
      </DetailFields>
    </Stack>
  );

  return (
    <DetailSection
      onEditBtnClick={showEditBtn ? props.onEditBtnClick : undefined}
    >
      {heading}
      <EventHistory
        currentUserData={props.currentUserData}
        labels={censusLabels}
        spec={{
          censusDateStart: { dateType: "date" },
          censusDateEnd: { dateType: "date" },
        }}
        viewJournalPerm="view_census_record_journal"
        eventHistory={props.eventHistory}
      >
        <>{skeleton || detail}</>
      </EventHistory>
    </DetailSection>
  );
}

export function CensusDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: censusRecord } = useCensusRecord(
    props.currentUserData.accessToken,
    uuid,
  );
  const { data: censusEvents } = useCensusEvents(
    props.currentUserData.accessToken,
    // This sneaks a conditional call into a react hook, which is necessary since
    // a user that can see a record might not be able to see the journal
    // and they'll receive a 403 when trying to call this function.
    // TODO: This maybe suggests something wrong with this permission structure?
    hasPermissions(props.currentUserData, "view_census_record_journal")
      ? uuid
      : undefined,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  const cmtCertPerms = checkCommentCertPerms(props.currentUserData);

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <CensusDetail
            currentUserData={props.currentUserData}
            record={censusRecord}
            onEditBtnClick={() => navigate("./edit")}
            eventHistory={censusEvents}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="census"
            defaults={
              cmtCertPerms.staff ? DefaultVitalRecsCommenter : undefined
            }
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}

export function CensusMediaPage(props: DefaultPageProps) {
  const { uuid } = useParams();

  const { data: censusImage, isLoading } = useCensusImage(
    props.currentUserData.accessToken,
    uuid,
  );

  const spinner = isLoading && !censusImage && <Spinner size="large" />;

  const imageDisplay = !isLoading && censusImage && (
    <ZoomableImage
      src={censusImage.content}
      alt="Page from which this census record came."
    />
  );

  return (
    <BreadcrumbPage breadcrumbAction="back">
      <Card>{spinner || imageDisplay}</Card>
    </BreadcrumbPage>
  );
}
