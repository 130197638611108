import { Button, Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
} from "../Detail";
import {
  useWWIServiceEvents,
  useWWIServicePDF,
  useWWIServiceRecord,
} from "../hooks/wwiServiceHooks";
import EventHistory from "../journal/EventHistory";
import { wwiServiceLabels, WWIServiceRecord } from "../schemas/wwiService";
import Skeleton from "../Skeleton";
import { DefaultPageProps } from "../utils/shared";
import ZoomablePDF from "../ZoomablePDF";
import CommentSection from "./CommentSection";
import {
  checkCommentCertPerms,
  DefaultVitalRecsCommenter,
} from "../schemas/comment";

export default function WWIServiceDetail(
  props: BaseDetailCompProps<WWIServiceRecord>,
) {
  const wwiServiceRecord = props.record;
  const coords = wwiServiceRecord?.residenceCoordinates;

  const { data: pdfContent, isLoading: pdfLoading } = useWWIServicePDF(
    props.currentUserData.accessToken,
    wwiServiceRecord?.pdfSHA256,
  );

  const pdfSpinner = pdfLoading && !pdfContent && <Spinner size="large" />;

  const pdf = !pdfLoading && pdfContent && (
    <Stack>
      <Subheading>Associated PDF</Subheading>
      <ZoomablePDF
        src={pdfContent.content}
        fileName={wwiServiceRecord?.certificateId?.toString()}
      />
    </Stack>
  );

  const skeleton = !wwiServiceRecord && <Skeleton lines={15} />;

  const showEditBtn = hasPermissions(
    props.currentUserData,
    "add_edit_remove_wwi_service_records",
  );

  const heading = wwiServiceRecord && (
    <Stack spacing={1}>
      <Heading>
        {wwiServiceRecord.firstName} {wwiServiceRecord.middleName}{" "}
        {wwiServiceRecord.familyName}
      </Heading>
      <Subheading>{wwiServiceRecord.id}</Subheading>
    </Stack>
  );

  const detail = wwiServiceRecord && (
    <Stack>
      {pdfSpinner || pdf}
      <Divider />
      <DetailFields
        record={wwiServiceRecord}
        spec={{
          certificateId: null,
          prefix: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          birthDateStart: { dateType: "date" },
          birthDateEnd: { dateType: "date" },
          locatorNumber: null,
          residencePriorToService: null,
          notes: null,
        }}
        labels={wwiServiceLabels}
      >
        <Subheading>{wwiServiceLabels["residenceCoordinates"]}</Subheading>
        {coords ? (
          <PText>
            Latitude: {coords.lat}, Longitude: {coords.lon}
          </PText>
        ) : (
          "No residence coordinates"
        )}
        <ListFieldStack
          label={wwiServiceLabels["alternateNames"]}
          elements={wwiServiceRecord.alternateNames || []}
        />
      </DetailFields>
    </Stack>
  );

  return (
    <DetailSection
      onEditBtnClick={showEditBtn ? props.onEditBtnClick : undefined}
    >
      {heading}
      {hasPermissions(props.currentUserData, "view_public_collections_items") &&
        wwiServiceRecord && (
          <Stack direction="row" align="center">
            <Button
              onClick={() =>
                window.open(
                  `/rma/collections/catalogue-items/${wwiServiceRecord.id}`,
                )
              }
            >
              Go to CMS Item
            </Button>
          </Stack>
        )}
      <EventHistory
        currentUserData={props.currentUserData}
        labels={wwiServiceLabels}
        spec={{
          birthDateStart: { dateType: "date" },
          birthDateEnd: { dateType: "date" },
        }}
        viewJournalPerm="view_wwi_service_record_journal"
        eventHistory={props.eventHistory}
      >
        <>{skeleton || detail}</>
      </EventHistory>
    </DetailSection>
  );
}

export function WWIServiceDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: wwiServiceRecord } = useWWIServiceRecord(
    props.currentUserData.accessToken,
    uuid,
  );
  const { data: wwiServiceEvents } = useWWIServiceEvents(
    props.currentUserData.accessToken,
    // This sneaks a conditional call into a react hook, which is necessary since
    // a user that can see a record might not be able to see the journal
    // and they'll receive a 403 when trying to call this function.
    // TODO: This maybe suggests something wrong with this permission structure?
    hasPermissions(props.currentUserData, "view_wwi_service_record_journal")
      ? uuid
      : undefined,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  const cmtCertPerms = checkCommentCertPerms(props.currentUserData);

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <WWIServiceDetail
            currentUserData={props.currentUserData}
            record={wwiServiceRecord}
            onEditBtnClick={() => navigate("./edit")}
            eventHistory={wwiServiceEvents}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="wwi-service"
            defaults={
              cmtCertPerms.staff ? DefaultVitalRecsCommenter : undefined
            }
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
