import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ChoiceList } from "@shopify/polaris";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { hasPermissions } from "../auth/authutils";
import { BaseFormCompProps, FormFields, FormSection } from "../Form";
import { useDeleteComment, useUpdateComment } from "../hooks/commentHooks";
import {
  checkCommentCertPerms,
  Comment,
  CommentInput,
  commentInputSchema,
  commentLabels,
} from "../schemas/comment";
import { CurrentUserData } from "../schemas/core";
import { DeletePrompt, DeleteSuccessPrompt } from "../utils/DeleteComponents";
import Prompt from "../utils/Prompt";
import Stack from "../../shared/Stack";
import { Subheading } from "../../shared/TextComponents";
import CommentDetail from "./CommentDetail";
import Card from "../../shared/Card";
import { removeNulls } from "../utils/shared";

interface CommentFormProps extends BaseFormCompProps<Comment> {
  disableDelete?: boolean;
  hideAccessLevel?: boolean;
  defaults?: CommentInput;
}

export default function CommentForm({
  currentUserData,
  record,
  onSubmitSuccess,
  onDeleteSuccess,
  additionalActions,
  disableDelete,
  hideAccessLevel,
  defaults,
}: CommentFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<CommentInput>({
    resolver: yupResolver(commentInputSchema),
    defaultValues: commentInputSchema.cast(record || defaults),
  });

  const cmtPerms = checkCommentCertPerms(currentUserData);

  const certOpts = removeNulls([
    cmtPerms["basic"] ? { label: "Public", value: "basic" } : null,
    cmtPerms["staff"] ? { label: "Staff", value: "staff" } : null,
    cmtPerms["mdh"] ? { label: "MDH Staff", value: "mdh" } : null,
  ]);

  return (
    <FormSection
      currentUserData={currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={commentInputSchema}
      initialValues={record}
      mutateHook={useUpdateComment}
      deleteHook={disableDelete ? undefined : useDeleteComment}
      onMutateSuccess={(result) => {
        reset();
        onSubmitSuccess(result);
      }}
      onDeleteSuccess={onDeleteSuccess}
      additionalActions={additionalActions}
    >
      <FormFields
        control={control}
        spec={{
          userName: { type: "text", required: true },
          email: { type: "text", required: true },
          content: { type: "text", required: true, lines: 5 },
        }}
        labels={commentLabels}
        errors={{
          userName: errors.userName,
          email: errors.email,
          content: errors.content,
        }}
        reset={reset}
        getValues={getValues}
      />
      <Controller
        name="certification"
        control={control}
        defaultValue="basic"
        render={({ field }) => (
          <ChoiceList
            title={"Source"}
            choices={certOpts}
            selected={[field.value]}
            onChange={(selected) => field.onChange(selected[0])}
          />
        )}
      />
      {!hideAccessLevel && (
        <Controller
          name="accessLevel"
          control={control}
          defaultValue="public"
          render={({ field }) => (
            <ChoiceList
              title={"Status"}
              choices={[
                { label: "Public", value: "public" },
                { label: "Hidden", value: "unpublished" },
              ]}
              selected={[field.value]}
              onChange={(selected) => field.onChange(selected[0])}
            />
          )}
        />
      )}
    </FormSection>
  );
}

interface CommentFormModalProps {
  currentUserData: CurrentUserData;
  record?: Comment;
  modalActive: boolean;
  setModalActive: (value: boolean) => void;
  promptTitle: string;
  replyToCmt?: Comment;
  onClose?: () => void;
  keepFormVisible?: boolean;
  hideAccessLevel?: boolean;
  defaults?: CommentInput;
}

export function CommentFormModal(props: CommentFormModalProps) {
  const [deletePromptActive, setDeletePromptActive] = useState(false);
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);

  const {
    modalActive,
    setModalActive,
    promptTitle,
    record,
    replyToCmt,
    onClose,
    keepFormVisible,
  } = props;

  const deleteMutation = useDeleteComment();

  const closeCommentFormModal = () => {
    setModalActive(false);
    if (onClose) {
      onClose();
    }
  };

  const toggleDeletePrompt = () => setDeletePromptActive((current) => !current);

  const form = (
    <CommentForm
      {...props}
      disableDelete
      onSubmitSuccess={closeCommentFormModal}
      onDeleteSuccess={() => closeCommentFormModal()}
      additionalActions={
        hasPermissions(props.currentUserData, "delete_comments") &&
        record && (
          <Button
            tone="critical"
            onClick={() => {
              setModalActive(false);
              toggleDeletePrompt();
            }}
            disabled={deleteMutation.isLoading}
          >
            Delete
          </Button>
        )
      }
    />
  );

  const replyTo = replyToCmt && (
    <Stack>
      <Subheading>Replying To:</Subheading>
      <Card innerCard>
        <CommentDetail comment={replyToCmt} />
      </Card>
    </Stack>
  );

  const commentFormModal = (
    <Prompt
      active={modalActive}
      title={promptTitle}
      onClose={closeCommentFormModal}
    >
      <Stack>
        {replyTo}
        {form}
      </Stack>
    </Prompt>
  );

  const deletePrompt = record && (
    <DeletePrompt
      id={record.id}
      active={deletePromptActive}
      togglePrompt={toggleDeletePrompt}
      loading={deleteMutation.isLoading}
      onDelete={(id: string) => {
        deleteMutation.mutate(
          { accessToken: props.currentUserData.accessToken, uuid: id },
          {
            onSuccess: () => {
              setDeleteConfirmActive(true);
              toggleDeletePrompt();
            },
          },
        );
      }}
    />
  );

  const deleteSuccessPrompt = record && (
    <DeleteSuccessPrompt
      id={record.id}
      active={deleteConfirmActive}
      onAck={() => {
        closeCommentFormModal();
        setDeleteConfirmActive(false);
      }}
    />
  );

  return (
    <>
      {deleteSuccessPrompt}
      {deletePrompt}
      {commentFormModal}
      {keepFormVisible && form}
    </>
  );
}
