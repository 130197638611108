import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  getRole,
  getRoles,
  getRolesByUser,
  setPermissions,
  setSubRoles,
  updateRole,
} from "../api/endpoints";
import { RoleInput } from "../schemas/core";
import { axiosInstance, baseAxiosConfig } from "../api/shared";

export function useRole(accessToken: string, uuid?: string) {
  return useQuery(
    ["role", uuid],
    async () => (accessToken && uuid ? await getRole(accessToken, uuid) : null),
    // TODO: Make record stale time configurable at root.
    { staleTime: 1000 * 60 * 5 }, // 5 minutes
  );
}

export function useRoles(accessToken: string) {
  return useQuery(
    ["roles", accessToken],
    async () => (accessToken ? await getRoles(accessToken) : null),
    // TODO: Make record stale time configurable at root.
    { staleTime: 1000 * 60 * 5 }, // 5 minutes
  );
}

export function useRolesByUser(accessToken: string, user_uuid?: string) {
  return useQuery(
    ["user", "roles", accessToken, user_uuid],
    async () =>
      accessToken ? await getRolesByUser(accessToken, user_uuid) : null,
    // TODO: Make record stale time configurable at root.
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  );
}

export function useUpdateRole() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: RoleInput;
    }) => {
      return updateRole(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([["role"], ["roles"]]);
      },
    },
  );
}

export function useSetRolePermissions() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      roleUUID,
      permissionIDs,
    }: {
      accessToken: string;
      roleUUID: string;
      permissionIDs: string[];
    }) => {
      return setPermissions(accessToken, roleUUID, permissionIDs);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["role"]);
      },
    },
  );
}

export function useSetSubRoles() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      roleUUID,
      subRoleUUIDs,
    }: {
      accessToken: string;
      roleUUID: string;
      subRoleUUIDs: string[];
    }) => {
      return setSubRoles(accessToken, roleUUID, subRoleUUIDs);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["role"]);
      },
    },
  );
}

export function useRemoveDeprecatedPermsFromRoles() {
  const queryClient = useQueryClient();

  const removeDeprecatedPerms = async (
    accessToken: string,
  ): Promise<string> => {
    const resp = await axiosInstance.put(
      "/auth/roles/remove-deprecated-permissions",
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };

  return useMutation(
    ({ accessToken }: { accessToken: string }) => {
      return removeDeprecatedPerms(accessToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["roles"]);
      },
    },
  );
}
