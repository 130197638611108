import { Button, Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
} from "../Detail";
import {
  useWWIBonusEvents,
  useWWIBonusPDF,
  useWWIBonusRecord,
} from "../hooks/wwiBonusHooks";
import EventHistory from "../journal/EventHistory";
import { wwiBonusLabels, WWIBonusRecord } from "../schemas/wwiBonus";
import Skeleton from "../Skeleton";
import { DefaultPageProps } from "../utils/shared";
import ZoomablePDF from "../ZoomablePDF";
import CommentSection from "./CommentSection";
import { getVitalRecordsDisplayName } from "./shared";
import {
  checkCommentCertPerms,
  DefaultVitalRecsCommenter,
} from "../schemas/comment";

export default function WWIBonusDetail(
  props: BaseDetailCompProps<WWIBonusRecord>,
) {
  const wwiBonusRecord = props.record;
  const coords = wwiBonusRecord?.residenceCoordinates;

  const { data: pdfContent, isLoading: pdfLoading } = useWWIBonusPDF(
    props.currentUserData.accessToken,
    wwiBonusRecord?.pdfSHA256,
  );

  const pdfSpinner = pdfLoading && !pdfContent && <Spinner size="large" />;

  const pdf = !pdfLoading && pdfContent && (
    <Stack>
      <Subheading>Associated PDF</Subheading>
      <ZoomablePDF
        src={pdfContent.content}
        fileName={wwiBonusRecord?.certificateId?.toString()}
      />
    </Stack>
  );

  const skeleton = !wwiBonusRecord && <Skeleton lines={15} />;

  const showEditBtn = hasPermissions(
    props.currentUserData,
    "add_edit_remove_wwi_bonus_records",
  );

  const heading = wwiBonusRecord && (
    <Stack spacing={1}>
      <Heading>{getVitalRecordsDisplayName(wwiBonusRecord)}</Heading>
      <Subheading>{wwiBonusRecord.id}</Subheading>
    </Stack>
  );

  const detail = wwiBonusRecord && (
    <Stack>
      {pdfSpinner || pdf}
      <Divider />
      <DetailFields
        record={wwiBonusRecord}
        spec={{
          certificateId: null,
          prefix: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          locatorNumber: null,
          residence: null,
          payeeName: null,
          notes: null,
          alternateId: null,
          outcome: null,
        }}
        labels={wwiBonusLabels}
      >
        <Subheading>{wwiBonusLabels["residenceCoordinates"]}</Subheading>
        {coords ? (
          <PText>
            Latitude: {coords.lat}, Longitude: {coords.lon}
          </PText>
        ) : (
          "No residence coordinates"
        )}
        <ListFieldStack
          label={wwiBonusLabels["alternateNames"]}
          elements={wwiBonusRecord.alternateNames || []}
        />
      </DetailFields>
    </Stack>
  );

  return (
    <DetailSection
      onEditBtnClick={showEditBtn ? props.onEditBtnClick : undefined}
    >
      {heading}
      {hasPermissions(props.currentUserData, "view_public_collections_items") &&
        wwiBonusRecord && (
          <Stack direction="row" align="center">
            <Button
              onClick={() =>
                window.open(
                  `/rma/collections/catalogue-items/${wwiBonusRecord.id}`,
                )
              }
            >
              Go to CMS Item
            </Button>
          </Stack>
        )}
      <EventHistory
        currentUserData={props.currentUserData}
        labels={wwiBonusLabels}
        spec={{}}
        viewJournalPerm="view_wwi_bonus_record_journal"
        eventHistory={props.eventHistory}
      >
        <>{skeleton || detail}</>
      </EventHistory>
    </DetailSection>
  );
}

export function WWIBonusDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: wwiBonusRecord } = useWWIBonusRecord(
    props.currentUserData.accessToken,
    uuid,
  );
  const { data: wwiBonusEvents } = useWWIBonusEvents(
    props.currentUserData.accessToken,
    // This sneaks a conditional call into a react hook, which is necessary since
    // a user that can see a record might not be able to see the journal
    // and they'll receive a 403 when trying to call this function.
    // TODO: This maybe suggests something wrong with this permission structure?
    hasPermissions(props.currentUserData, "view_wwi_bonus_record_journal")
      ? uuid
      : undefined,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  const cmtCertPerms = checkCommentCertPerms(props.currentUserData);

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <WWIBonusDetail
            currentUserData={props.currentUserData}
            record={wwiBonusRecord}
            onEditBtnClick={() => navigate("./edit")}
            eventHistory={wwiBonusEvents}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="wwi-bonuses"
            defaults={
              cmtCertPerms.staff ? DefaultVitalRecsCommenter : undefined
            }
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
