import { Button, Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import { BaseDetailCompProps, DetailFields, DetailSection } from "../Detail";
import {
  useDeleteWWISolPhoto,
  useWWISoldiersPhoto,
  useWWISolPhotoMedias,
} from "../hooks/wwiSolPhotoHooks";
import { wwiSolPhotoLabels, WWISolPhotoRecord } from "../schemas/wwiSolPhoto";
import Skeleton from "../Skeleton";
import { DefaultPageProps } from "../utils/shared";
import { ZoomableImageGallery } from "../ZoomableImage";
import CommentSection from "./CommentSection";
import { getVitalRecordsDisplayName } from "./shared";
import { useState } from "react";
import { DeletePrompt, DeleteSuccessPrompt } from "../utils/DeleteComponents";
import {
  checkCommentCertPerms,
  DefaultVitalRecsCommenter,
} from "../schemas/comment";

export default function WWISolPhotoDetail(
  props: BaseDetailCompProps<WWISolPhotoRecord>,
) {
  const [deletePromptActive, setDeletePromptActive] = useState(false);
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);

  const navigate = useNavigate();

  const wwiSolPhoto = props.record;

  const deleteMutation = useDeleteWWISolPhoto();

  const images = useWWISolPhotoMedias(
    props.currentUserData.accessToken,
    wwiSolPhoto?.imageSHA256s || [],
  );

  const toggleDeletePrompt = () => setDeletePromptActive((current) => !current);

  const deleteButton = hasPermissions(
    props.currentUserData,
    "edit_remove_wwi_soldiers_photos_records",
  ) && (
    <Button tone="critical" onClick={toggleDeletePrompt}>
      Delete
    </Button>
  );

  const deletePrompt = wwiSolPhoto && (
    <DeletePrompt
      id={wwiSolPhoto.id}
      active={deletePromptActive}
      togglePrompt={toggleDeletePrompt}
      onDelete={(uuid: string) => {
        // Should this also delete the record from catalogue-items?
        deleteMutation.mutate(
          { accessToken: props.currentUserData.accessToken, uuid },
          {
            onSuccess: (result) => {
              setDeleteConfirmActive(true);
              toggleDeletePrompt();
            },
          },
        );
      }}
    />
  );

  const deleteSuccessPrompt = wwiSolPhoto && (
    <DeleteSuccessPrompt
      id={wwiSolPhoto.id}
      active={deleteConfirmActive}
      onAck={() => navigate("../")}
    />
  );

  const imageGallery = images && wwiSolPhoto && (
    <Stack>
      <Subheading>Associated Images</Subheading>
      <ZoomableImageGallery
        images={images.map((img, idx) => ({
          src: img.data?.content,
          alt: `Image ${idx + 1} for ${getVitalRecordsDisplayName(wwiSolPhoto)}`,
          fileName: `${getVitalRecordsDisplayName(wwiSolPhoto, "")}Image${idx + 1}.jpg`,
          isLoading: img.isLoading,
        }))}
      />
    </Stack>
  );

  const skeleton = !wwiSolPhoto && <Skeleton lines={15} />;

  const heading = wwiSolPhoto && (
    <Stack spacing={1}>
      <Heading>{getVitalRecordsDisplayName(wwiSolPhoto)}</Heading>
      <Subheading>{wwiSolPhoto.id}</Subheading>
    </Stack>
  );

  const detail = wwiSolPhoto && (
    <Stack>
      {imageGallery}
      <Divider />
      <DetailFields
        record={wwiSolPhoto}
        spec={{
          prefix: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          residence: null,
          notes: null,
          alternateId: null,
        }}
        labels={wwiSolPhotoLabels}
      ></DetailFields>
    </Stack>
  );

  return (
    <DetailSection>
      {deletePrompt}
      {deleteSuccessPrompt}
      {heading}
      {wwiSolPhoto && (
        <Stack direction="row" align="center">
          <Button
            onClick={() =>
              window.open(`/rma/collections/catalogue-items/${wwiSolPhoto.id}`)
            }
          >
            Go to CMS Item
          </Button>
        </Stack>
      )}
      <>{skeleton || detail}</>
      <Stack direction="row">
        {deleteMutation.isLoading && <Spinner size="small" />}
        {deleteButton || null}
      </Stack>
    </DetailSection>
  );
}

export function WWISolPhotoDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: wwiSolPhotoRecord } = useWWISoldiersPhoto(
    props.currentUserData.accessToken,
    uuid,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  const cmtCertPerms = checkCommentCertPerms(props.currentUserData);

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <WWISolPhotoDetail
            currentUserData={props.currentUserData}
            record={wwiSolPhotoRecord}
            onEditBtnClick={() => navigate("./edit")}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="wwi-soldiers-photos"
            defaults={
              cmtCertPerms.staff ? DefaultVitalRecsCommenter : undefined
            }
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
